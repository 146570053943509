@charset"UTF-8";

//変数などを定義
$font_montserrat: 'Montserrat', sans-serif;

@mixin flex($justify:center, $align:center) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
}

@mixin mq($breakpoint: sm) {
    @media #{map-get($breakpoints, $breakpoint)} {
      @content;
    }
}
$breakpoints: (
  'min': 'screen and (max-width: 375px)',
  'sm': 'screen and (max-width: 900px)',
  'md': 'screen and (max-width: 768px)',
  'lg': 'screen and (max-width: 1000px)',
  'xl': 'screen and (max-width: 1200px)',
) !default;