@charset "utf-8";

@use "../constant" as constant;

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

html, body {
    background-color: white;
    color: #333;
    font-family: 'Questrial', 'Noto Sans JP', sans-serif;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#mainwrap {
    text-align: center;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

/* header */
header {
	position:relative;
    display: flex;
    flex-direction: column;

    @include constant.mq(md) {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index:5;
    } 
}
/*
これはヘッダーに入れた場合
#gunness_pc{
	position:absolute;
	right:100px;
	top:16.4vw;
	width:200px;
}*/
#gunness_pc, #futuretimes{
	margin-top:30px;
	width:240px;
}
#gunness_pc img, #futuretimes img{
	width:100%;
}
@media screen and (max-width: 1399px) {
	#gunness_pc, #futuretimes{
		display:none;
	}
}

#headertop {
    position: relative;
    background-color: #222;
    width:100%;
    height:15.6vw;

    a {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}
#headermiddle {
    background-color: #221815;
}
.mainheader {
    width: 850px;
    height: 65px;
    margin: 0 auto;
    display: flex;
    justify-content: left;
    align-items: center;

    @include constant.mq(md) {
        display: none;
    }

    &__title {
        font-size: 24px;
        margin: 0 20px 4px 0;
        @include constant.mq() {
            font-size: 20px;
        }
        @include constant.mq(min) {
            font-size: 16px;
            margin-right: 16px;
        }
        a {
            color: #fff;
            text-decoration: none;

            &:hover {
                border-bottom: 2px solid #fff;
            }
        }
    }
    &__logo {
        width: 90px;
        @include constant.mq(min) {
            width: 70px;
        }
    }
    @include constant.mq() {
        width: 100%;
        justify-content: center;
        
    }
}
.headerbottom {
    background-color: #444;
    line-height: 25px;
    padding: 16px 0;
    @include constant.mq(md) {
        padding: 7px 0;
    }
    &__list {
        width: 850px;
        color: #fff;
        display: flex;
        justify-content: left;
        margin: 0 auto;

        @include constant.mq() {
            width: 100%;
            justify-content: center;
            flex-wrap: wrap;
        }
    }
}
.headermiddleinnerlist {
    text-align: left;
    padding: 0px 5px;
    min-width: 150px;
}
.headermiddleinnerlist a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.headermiddleinnerlist a:hover,
.headermiddleinnerlist a:active {
    text-decoration: underline;
}

/* content */
#content {
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 20px;
}
#contentleft {
    width: 30%;
}
#contentcenter {
    width: 100%;
    min-width: 0;
}
#contentright {
    width: 30%;
}
@media screen and (min-width: 1440px) {
    #guinness_box{
        position:relative;
        padding:20px 0px;
    }
    #guinness_box img, #futuretimes_sma img {
        width:100%;
    }
}
#guinness_sma, #futuretimes_sma {
    display:none;
}


#floatingwrap {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}
#floatsnswrap {
    padding: 0px 0px 50px;
}
#floatsnswrap .snsiconwrap {
    line-height: 48px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 20px 10px;
    height: 48px;
}
.snsiconwrap img {
    margin: 0px 10px 0px 0px;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
}
.snsiconwrap a {
    color: inherit;
    text-decoration: none;
}
.snsiconwrap a:hover,
.snsiconwrap a:active {
    text-decoration: underline;
}
#twittertimeline {
    background-color: #ccc;
    min-height: 400px;
    max-height: 400px;
}
#contentleftheaderline {
    background-color: #ccc;
    height: 15px;
}
#profilewrap {
    padding: 50px 10px 10px 10px;
    max-width: 500px;
    margin: 0 auto;
}
#profileimagewrap {
    text-align: center;
}
#profileimagewrap img {
    border-radius: 50%;
    width: 128px;
    height: 128px;
    object-fit: cover;
    box-shadow: 1px 1px 5px #aaa;
}
#profiletitlewrap {
    font-size: large;
    font-weight: bold;
    text-align: center;
    margin: 20px 0px;
}
#profiletitleunderline {
    background-color: #ddd;
    border-radius: 3px;
    margin: 20px auto 20px;
    width: 30px;
    height: 4px;
}
#profilecontentwrap {
    font-size: 14px;
    line-height: 22px;
}
#profileiconwrap {
    margin: 25px auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content:space-between;
}
.profilesnsicon {
    margin-right:10px;
    a{
        display: block;
        width: 35px;
        height: 35px;
        margin-bottom:10px;
    }
    &:last-child{
        margin-right:0px;
    }
}
.profilesnsicon img {
    width: 100%;
}
#profileunderline {
    background-color: #ccc;
    margin: 0px auto;
    width: 100%;
    height: 1px;
}
input[type="text"] {
    outline: none;

    &:hover {
        cursor: pointer;
    }
}
input[type="submit"] {
    cursor: pointer;
}
#contentlefttop form {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0 5px;
}
#contentlefttop input[type=text] {
    line-height: 24px;
    padding: 5px 10px;
    width: 100%;
}
#contentlefttop input[type=submit] {
    line-height: 24px;
    padding: 5px 10px;
    max-width: 80px;
}
#contentleftblockwrap {
    margin: 0px 0px 20px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;

    @include constant.mq(md) {
        position: inherit;
    }
}
.contentleftblock {
    padding: 0px 0px 10px;
    min-height: 300px;
}
.contentrightblocktitle,
.contentleftblocktitle {
    font-weight: bold;
    line-height: 32px;
    margin: 30px auto;
    min-height: 32px;
}
.contentrightblocktitle::before,
.contentleftblocktitle::before {
    content: ' ';
    background-color: #ccc;
    border-radius: 3px;
    margin: 4px 5px 4px 0px;
    width: 3px;
    height: 24px;
    display: inline-block;
    vertical-align: bottom;
}
.contentleftblocklist {
    list-style: none;
    margin: 0px 0px 20px;
    padding: 0px;
}
.contentleftblocklist li {
    font-size: 14px;
    line-height: 16px;
    margin: 16px 0px;
    display: flex;
}
.contentleftblocklist li::before {
    content: ' ';
    background-image: url('../../../resource/right-arrow.png');
    background-repeat: no-repeat;
    line-height: 16px;
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: bottom;
}
.contentleftblocklist a {
    color: inherit;
    text-decoration: none;
    display: inline-block;
    margin-left: 4px;
}
.contentleftblocklist a:hover {
    text-decoration: underline;
    color: #197BBD;
}
.contentleftblockbanner {
    text-align: center;
}
.contentleftblockbanner img {
    margin: 0px 0px 20px;
    max-width: 300px;
}



article {
    padding: 40px;

    @include constant.mq(md) {
        padding: 0;
    }
}
.contentheader {
    overflow:hidden;
    margin-bottom:20px;
}
.contentheader h1 {
    font-size: 24px;
}
.contentheadertop {
    font-size: 14px;
    text-align: left;
    padding: 10px 0;
}
.rightarrow {
    padding: 0 7px;
}
.contentheaderbottom {
    font-size: small;
}
.contentheadertop a {
    display: inline-block;
    color: #333;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        color: #197BBD;
    }
}
.thumbnailiconwrap {
    min-height: 128px;
    max-height: 128px;
    display: flex;
    flex-direction: row;
}
.thumbnailicon {
    border-radius: 32px;
    margin: 32px 20px 32px 20px;
    min-width: 64px;
    max-width: 64px;
    min-height: 64px;
    max-height: 64px;
    display: block;
}
.thumbnailcaption {
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.articlepropertywrap {
    line-height: 24px;
    display: flex;
    flex-direction: row;
}
.articlepropertycategorywrap {
    display: flex;
    flex-direction: row;
}
.articlecategory {
    border: 1px solid silver;
    border-radius: 20px;
    background-color: white;
    color: silver;
    text-align: center;
    text-decoration: none;
    margin: 8px 0px;
    min-width: 100px;
    display: block;
    transition: background-color 0.25s ease, color 0.25s ease;
}
.articlepropertycategorydate {
    text-align: right;
    letter-spacing: 2px;
    margin: 0px 0px 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contentmain {
    margin: 0px 0px 60px;
}
.topimagewrap {
    margin: 20px 0px;
}
.topimagewrap img {
    width: 100%;
}
.copy_pho {
    margin: 20px 0px;
}
.contentmain h2 {
    border-bottom: 2px solid;
    margin: 30px 0px 0px;
    padding: 0px 0px 5px;
}
.contentmain h3 {
    line-height: 30px;
    margin: 30px 0px 0px;
    height: 30px;
}
.contentmain h3::before {
    content: ' ';
    background-color: #ccc;
    border-radius: 3px;
    margin: 4px 10px 4px 2px;
    width: 4px;
    height: calc(100% - 8px);
    display: inline-block;
    vertical-align: bottom;
}
.contentmain a {
    text-decoration: none;
}
.contentmain a:hover,
.contentmain a:active {
    text-decoration: underline;
}
.contentmain p {
    margin: 10px 20px;
}
.contentinfosnsouter {
    padding: 20px;

    @include constant.mq() {
        padding: 0;
    }

    h4 {
        font-size: large;
        font-weight: bold;
        letter-spacing: 2px;
    }
}
.contentinfosnswrap {
    border: 1px solid #eee;
    margin: 20px 0;
    padding: 30px 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    @include constant.mq() {
        padding: 30px;
    }
}
.contentinfosnsicon {
    width: 100%;
    min-height: 50px;
    max-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    a {
        display: block;
        width: 50px;
        height: 50px;
        margin: auto;
        &:hover{
            opacity:0.7;
        }
    }
    img {
        width: 50px;
        height: 50px;
    }
    .link_button{
        display:flex;
        align-items: center;
        justify-content: center;
        width:50px;
        height:50px;
        border-radius:50%;
        margin:0 auto;
        background-color:#4b4b4b;
        img{
            width: 30px;
            height: 30px;
        }
        &:hover{
            cursor: pointer;
            opacity:0.7;
        }
    }
}
/*後から追加したもの*/
.contentsbanner{
    margin: 0px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;	
}
@media screen and (min-width: 1440px) {
.contentsbanner img{
	margin:0px 10px;
}
}
@media screen and (max-width: 1439px) {
.contentsbanner img{
	width:95%;
	margin:0px 10px;
}
}

#pagerwrap {
    margin: 30px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
	overflow:hidden;
}
.pageritem {
    border: 1px solid #555;
    line-height: 35px;
    text-align: center;
    margin: 20px 5px;
    min-width: 35px;
    max-width: 35px;
    min-height: 35px;
    max-height: 35px;

    &:hover {
        border: 1px solid #888;
    }
}
.pageritem a {
    background-color: #555;
    color: white;
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: block;
}
.pageritem a:hover,
.pageritem a:active {
    background-color: #888;
    text-decoration: none;
}
.articlecard {
    color: inherit;
    text-decoration: none;
    padding: 20px;
    display: flex;
    flex-direction: row;
}
.articlecard ~ .articlecard {
    border-top: 1px solid silver;
}
.articlecardimage {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-width: 300px;
    min-height: 220px;
}
.articlecardwrap {
    margin: 30px 0;
    padding: 0px 0px 0px 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.articlecardtitlewrap {
    display: flex;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
}
.articlecardtitle {
    font-size: 18px;
    font-weight: bold;
    line-height: 25px;
    margin: 0px 5px 5px 5px;
    text-overflow: ellipsis;
}
.articlecardcategorywrap {
    margin: auto 0px 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    a {
        text-decoration: none;
    }
}
.articlecardcategory {
    width: 100px;
    border-radius: 15px;
    font-size: small;
    line-height: 22px;
    text-align: center;
    margin: 0px 5px 10px 5px;
    padding: 0px 10px;
}
.articlecardtext {
    margin: 0 5px 0 5px;
    word-break: break-all;
}
.articlecarddate {
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 20px;
    min-height: 20px;
    max-height: 20px;
    margin-bottom: 5px;
}
.articlecard:hover,
.articlecard:active {
    text-decoration: none !important;
}
.articlecard:hover .articlecardtitle {
    text-decoration: underline !important;
    color: #197BBD;
}

.contentfooter {
    // border: 1px solid #666;
    padding: 20px;
    min-height: 300px;

    @include constant.mq() {
        padding: 0;
    }
}
.contentfooter h4 {
    font-size: large;
    font-weight: bold;
    letter-spacing: 2px;
    margin: 0px 0px 15px;
}
.contentfooterwrap ~ .contentfooterwrap {
    margin: 20px 0px 0px;
}
.linkofarticle {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    color: inherit;
    text-decoration: none;
    padding: 10px;
    height: 130px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @include constant.mq(md) {
        flex-wrap: wrap;
        justify-content: left;
        height: auto;
    }
}
.linkofarticle ~ .linkofarticle {
    border-top: 0px;
}
.linkofarticleimage {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    min-width: 150px;
    max-width: 150px;
    height: 100px;
    overflow: hidden;
    margin-left: 10px;

    @include constant.mq(md) {
        width: 90%;
        min-width: inherit;
        max-width: 400px;
        padding-top: 60%;
        margin: 12px auto;
    }
}
.linkofarticlewrap {
    padding: 0px 0px 0px 10px;
    width: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.linkofarticletitle {
    font-weight: bold;
    line-height: 30px;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-height: 30px;
    max-height: 30px;
    overflow: hidden;

    @include constant.mq(md) {
        width: 85%;
        margin: 0 auto;
    }
}
.linkofarticlecaption {
    color: #666;
    font-size: x-small;
    text-overflow: ellipsis;
    margin: 0px 0px 0px 10px;
    height: calc(100% - 10px);
    min-height: 50px;
    max-height: 50px;
    overflow: hidden;

    @include constant.mq(md) {
        display: none;
    }
}
.linkofarticledate {
    font-size: small;
    letter-spacing: 1px;
    margin: 0px 10px 0px 0px;
    line-height: 20px;
    text-align: right;
    min-height: 20px;
    max-height: 20px;

    @include constant.mq(md) {
        width: 85%;
        margin: 0 auto;
        text-align: left;
    }
}
.linkofarticle:hover .linkofarticletitle {
    text-decoration: underline;
    color: #197BBD;
}
.contentmain img {
    width: 100%;
}
.contentmain blockquote {
    background-color: #eee;
    font-size: small;
    font-weight: normal;
    margin: 30px 10px;
    padding: 20px 20px 15px;
    width: calc(100% - 20px);
}
.contentmain strong {
    color: deeppink;
    font-weight: bold;
}
.contentmain ul {
    padding: 0px 0px 0px 40px;
    margin: 30px 0px;
}
.contentmain li {
    margin: 5px 0px;
}
.contentmain table,
.contentmain th,
.contentmain td {
    border: 1px solid #333;
    border-collapse: collapse;
}
.contentmain table {
    margin: 30px 20px;
    width: calc(100% - 40px);
}
.contentmain th {
    background-color: #ddd;
    padding: 5px 10px;
}
.contentmain td {
    padding: 5px 10px;
}
@media screen and (min-width: 1440px) {
    .Youtube_size {
        width: 730px;
        height: 411px;
    }
}
@media screen and (max-width: 1440px) {
    .Youtube_size {
        width: calc(100vw - 40px);
        height: calc((100vw - 40px) * 0.56);
    }
}

/* footer */
footer {
    display: flex;
    flex-direction: column;
}
#footertop {
    background-color: #b2b2b2;
    color: whitesmoke;
    min-height: 200px;
}
#footermiddle {
    background-color: #666;
    min-height: 100px;
    max-height: 100px;
}
#footerbottom {
    background-color: #221815;
    min-height: 200px;
}
#footertopwrap {
    margin: 0px auto;
    width: 100%;
    max-width: 1440px;
    min-height: 200px;
    display: flex;
    flex-direction: row;
}
#conceptwrap {
    text-content: center;
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
        width: 45%;
        margin: 0 auto;

        @include constant.mq() {
            width: 60%;
        }
        @include constant.mq(min) {
            width: 80%;
        }
    }
}
#conceptcopy {
    font-size: xx-large;
    letter-spacing: 5px;
    margin: 0px auto 20px;
}
#conceptcaption {
    font-size: small;
    letter-spacing: 2px;
}
#contactbuttonwrap {
    text-align: center;
    min-width: 640px;
    max-width: 640px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#contactwrap {
    width: 400px;
}
#contactbutton {
    background-color: white;
    border-radius: 5px;
    color: #333;
    letter-spacing: 4px;
    text-decoration: none;
    margin: auto;
    padding: 10px 50px;
    display: block;
    box-shadow: 2px 2px 1px 0px #666;
}
#contactbutton:hover,
#contactbutton:active {
    color: #666;
    box-shadow: -2px -2px 1px 0px #666;
    transform: translate(2px, 2px);
}
#footermiddlewrap {
    color: whitesmoke;
    margin: 0px auto;
    width: 100%;
    max-width: 1440px;
    min-height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
}
.footermiddleitem {
    color: inherit;
    text-align: center;
    text-decoration: none;
    padding: 0px 5px;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.footermiddleitem:hover,
.footermiddleitem:active {
    text-decoration: underline;
}
#footerbottomwrap {
    color: whitesmoke;
    margin: 0px auto;
    padding: 0px 0px 50px;
    width: 100%;
    max-width: 1440px;
    min-height: 300px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
#footerbottomleft {
    width: 100%;
    height: 100%;
}
#sitemapwrap {
    padding: 50px 0px 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
#sitemapwrap ul {
    margin: 0px 20px 0px 0px;
    padding: 0px 0px 10px 20px;
    list-style: none;
    text-align: left;
}
#sitemapwrap li {
    margin: 8px 0px;
}
#sitemapwrap a {
    color: inherit;
    font-size: small;
    text-decoration: none;
}
#sitemapwrap a:hover,
#sitemapwrap a:active {
    text-decoration: underline;
}
.sitemaplistwrap {
    margin: 0px 5px 0px 5px;
    width: 200px;
}
.sitemaplistwrap h6 {
    font-size: medium;
    font-weight: bold;
    text-align: left;
    margin: 10px 0px;
    padding: 0px 0px 0px 5px;
}
#footerbottomright {
    text-align: left;
    min-width: 530px;
    max-width: 530px;
}
#footerbottomlogowrap {
    padding: 50px 50px 10px;
}
#footerbottomlogowrap img {
    width: 100%;
    max-width: 200px;
}
.footerbottomaddresswrap {
    padding: 10px 50px;
}
.footerbottomaddresswrap h5 {
    font-size: small;
}
.footerbottomaddresswrap p {
    font-size: small;
    padding: 0px 10px;
}

#copyright {
    font-size: small;
    line-height: 32px;
}

/* wide */
@media screen and (min-width: 1440px) {
    #content {
        margin: 0px auto;
        width: 1440px;
    }
}

/* portrait and mobile */
@media screen and (max-width: 1140px) {
    #content {
        margin: 0px auto;
        width: 100%;
        flex-direction: column-reverse;
        justify-content: flex-start;
    }
    #contentleft {
        min-width: 100%;
        max-width: 100%;
    }
    #contentcenter {
        min-width: 100%;
        max-width: 100%;
    }
    #contentright {
        display: none;
    }
    #headertop {
        width:100%;
        height:15.6vw;
    }
    .headermiddleinnerlist {
        text-align: left;
        font-size: small;
        padding: 0px 8px;

        @include constant.mq() {
            min-width: inherit;
        }
    }
    #headerbottom {
        line-height: 50px;
        min-height: 50px;
    }
    #contentlefttop form {
        justify-content: left;
    }
    #contentlefttop input[type=text] {
        max-width: 350px;
    }
    .linkofarticlewrap {
        padding: 0px 0px 0px 5px;
    }
    .linkofarticlecaption {
        min-height: 56px;
        max-height: 56px;
    }
    .linkofarticledate {
        line-height: 12px;
        min-height: 12px;
        max-height: 12px;

    }
    .contentmain {
        margin: 0px auto 50px auto;
        max-width: 500px;
    }
    .articlepropertywrap {
        line-height: 18px;
        flex-direction: inherit;
        justify-content: space-between;
        align-items: center;
    }
    .articlecategory {
        font-size: small;
        padding: 2px 0;
        margin: 8px 0px;
    }
    .articlepropertycategorydate {
        font-size: small;
        margin: 2px 0px;
        width: 100px;
    }
    .contentheadertop {
        font-size: small;
        padding: 15px 0px;
    }
    .articlecard {
        padding: 30px 0px;
        height: auto;
        flex-direction: column;
    }
    .articlecardimage {
        margin: 0px auto;
        width: 100%;
        min-width: auto;
        max-width: inherit;
        padding-top: 60%;
    }
    .articlecardwrap {
        margin: 10px 0px 0px;
        padding: 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .articlecardcategorywrap {
        margin: 5px 0px;
    }
    .articlecarddate {
        margin: 0px 0px 0px 5px;
    }
    .contentinfosnsicon {
        min-height: 48px;
        max-height: 48px;
    }
    .contentinfosnsicon a {
        display: block;
        width: 48px;
        height: 48px;
        margin: auto;
    }
    .contentinfosnsicon img {
        width: 48px;
        height: 48px;
    }
    #contactbuttonwrap {
        margin: 0px 0px 50px;
        min-width: 100%;
        max-width: 100%;
        min-height: 60px;
    }
    #contactwrap {
        margin: auto;
        width: 300px;
    }
    #guinness_sma, #futuretimes_sma {
        width:100%;
        margin: 0px 0px 30px 0px;
        display: flex;
        flex-direction: row;
        justify-content: center;

        img {
            width:70%;
            max-width: 500px;
            margin-left:15%;
        }
    }
    #footertopwrap {
        flex-direction: column;
    }
    #footermiddle {
        min-height: 60px;
        max-height: inherit;
    }
    #footermiddlewrap {
        line-height: 40px;
        padding: 5px 20px;
        min-height: 50px;
        display: block;
    }
    .footermiddleitem {
        border: 1px solid #888;
        border-radius: 30px;
        text-align: center;
        line-height: 50px;
        margin: 5px;
        padding: 0px 10px;
        min-width: 50px;
        display: inline-block;
    }
    #footerbottomwrap {
        padding: 0px 0px 50px;
        flex-direction: column-reverse;
    }
    #footerbottomright {
        display: block;
        min-width: 100%;
        max-width: 100%;
    }
    #footerbottomlogowrap {
        width: 100%;
    }
    #sitemapwrap {
        font-size: small;
        padding: 20px 0px 0px;
        justify-content: center;
    }
    #sitemapwrap ul {
        margin: 0px 10px 0px 0px;
        padding: 0px 0px 10px 10px;
        list-style: none;
        text-align: left;
    }
    #sitemapwrap li {
        margin: 10px 0px;
    }
}

#footer {
	width: 100%;
	background-color: #221815;
	color: #fafafa;
	font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Helvetica Neue", HelveticaNeue, YuGothic, "Yu Gothic Medium", "Yu Gothic", Verdana, Meiryo, sans-serif;
	line-height: 18px;
	text-align: left;

	.footerinner {
		@include constant.mq() {
			@include constant.flex(flex-start, stretch);
			flex-direction: column-reverse;
			flex-wrap: wrap;
			width: 85%;
			margin: 0 auto;
		}
	}

	.footercontent {
		@include constant.flex(space-between, flex-start);
		max-width: 1000px;
		padding: 40px 16px 0 20px;
		margin: 0 auto;

		@include constant.mq() {
            width: 85%;
			display: block;
			padding-top: 0;
			margin: 0 auto;
		}  

	.footermenu {
		width: calc(100% / 4 - 12px);
		height: auto;
		min-height: 580px;
		border-right: 1px solid #fff;

		a {
			color: #fafafa;
		}

		@include constant.mq() {
			display: none;
		}

		&__category {
			font-weight: bold;
            margin: 18px 0 6px 0;
            
            a {
                text-decoration: none;
            }

			
		}
		&__product {
            font-size: 13px;
            list-style: none;
            margin: 0 0 6px 8px;
            
            a {
                text-decoration: none;
            }
		}
	}
}
	.corpinfowrap {
	margin-top: 12px;

	.corplogo {
		@include constant.mq() {
			display: none;
		}

		img {
			width: 120px;
		}
	}   
	.corpinfo {
		font-size: 13px;

		&__name {
			margin-top: 26px;

			@include constant.mq() {
				font-size: 20px;
				line-height: 26px;
			}
		}
		&__office {
			color: #3f3f3f;
			background-color: #fafafa;
			display: inline-block;
			font-weight: bold;
			padding: 5px 8px;
			margin-top: 20px;

			@include constant.mq() {
				display: block;
			}
		}
		&__address {
			margin-top: 8px;

			// iOS で電話番号と FAX 番号が青字になるのを防ぐ
			a {
				color: #fafafa;
			}
		}
	}
}
	.footerpartner {
	max-width: 1000px;
	padding: 0 16px;
	margin: 0 auto;

	@include constant.mq() {
		margin: 0;
	}

		.banner {
			@include constant.flex(space-between, flex-start);
			flex-wrap: wrap;

			&__img {
				@include constant.mq() {
					margin-bottom: 16px;
				}
				img {
					@include constant.mq() {
						width: 100%;
					}
				}
			}
		}
	}
}


.policy_wrap{
    margin-top:100px;
    display:flex;
    justify-content: center;
    p{
        font-size: 13px;
        margin-right:1em;
        a{
            color:#FFF;
            text-decoration: underline;
        }
        &:last-of-type{
            margin-right:0;
        }
    }
  
  }
  
  #copyright {
    font-size: small;
    padding: 10px 0 60px 0;
    text-align: center;
  }

.spfooterlogo {
	display: none;

	@include constant.mq() {
		@include constant.flex(space-around, center);
		width: 85%;
		max-width: 300px;
		margin: 32px auto 0 auto;
		}

		&__main {
			width: 50%;
		}
}

// フッターの見出し
.headingwhite {
    position:relative;
		font-weight: bold;
		line-height: 24px;
    margin: 40px 0;
    padding-left: 32px;

    &:before{
        content:"";
        display:inline-block;
        width: 8px;
        height: 45px;
        background-color: #fafafa;
        position:absolute;
        left: 0;
    }
    &__title {
        color: #fafafa;
        font-family: constant.$font_montserrat;
        font-size: 20px;
    }
    &__subtitle {
        font-size: 13px;
    }
}

//モーダル
.modal-wrap{
    position:absolute;
    top:0;
    left:0;
    height:100vh;
    width:100%;
    background-color:rgba(0,0,0,0.5);
    .modal-inner{
        max-height:300px;
        width:100%;
        max-width:500px;
        height:100%;
        margin:0 auto;
        padding:1em 2em;
        background-color:#FFFFFF;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        display:flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .input-text{
            padding:1em;
            width:300px;
            border: 1px solid #333;
        }
    }
    .modal-text{
        text-align: center;
        font-weight:bold;
        margin-bottom:25px;
    }
    input[type="submit"] {
        -webkit-appearance: none;
        border-radius: 0;
    }
    .modal-button{
        &-wrap{
            display:flex;
            margin-top:20px;
        }
        &-yes{
            text-align: center;
            min-width:100px;
            width:50%;
            padding:1em 1.5em;
            margin-right:20px;
            background-color:#333;
            color:#FFF;
            cursor:pointer;
            &:hover{
                opacity:0.7;        
            }
        }
        &-no{
            text-align: center;
            min-width:100px;
            width:50%;
            padding:1em 1.5em;
            border:1px solid #333;
            background-color:#FFF;
            color:#333;
            cursor:pointer;
            &:hover{
                opacity:0.7;        
            }
        }
        &-clear{
            text-align: center;
            width:40%;
            padding:1em 1.5em;
            margin:0 auto;
            background-color:#333;
            color:#FFF;
            cursor:pointer;
            &:hover{
                opacity:0.7;        
            }
        }

    }
}
